import { isEqual } from 'lodash';

class IHasTypeProperty<V> {
  public type: V;
}

export class DestructureHelper {
  public static overwriteAttributes<T>(object: T, attributes: Partial<T>): T {
    return Object.assign({}, object, attributes) as T;
  }

  public static findObjectIndex<V>(objects: any, compareType: V | V[]): number {
    let newCompareType = compareType;

    if (!Array.isArray(newCompareType)) {
      newCompareType = [newCompareType];
    }

    return objects?.findIndex((obj) => {
      return (newCompareType as V[]).includes(obj?.type);
    });
  }

  public static findAndOverwriteAttributes<T extends IHasTypeProperty<V>, V>(
    objects: T[],
    compareType: V | V[],
    attributes: Partial<T>,
  ): T {
    const index = DestructureHelper.findObjectIndex<V>(objects, compareType);

    return DestructureHelper.overwriteAttributes(objects[index], attributes);
  }

  public static findAndOverwriteAttributesInList<T extends IHasTypeProperty<V>, V>(
    objects: T[],
    compareType: V | V[],
    attributes: Partial<T>,
  ): T[] {
    const index = DestructureHelper.findObjectIndex<V>(objects, compareType);

    if (Number.isInteger(index)) {
      objects[index] = DestructureHelper.overwriteAttributes(objects[index], attributes);
    }

    return objects;
  }

  public static filterAndOverwriteAttributesInList<T extends IHasTypeProperty<V>, V>(
    objects: T[],
    compareTypes: V[],
    attributes: Partial<T>,
  ): T[] {
    const filteredObjects = objects.filter((obj) => {
      return compareTypes.includes(obj.type);
    });

    filteredObjects.forEach((filteredObject) => {
      const index = objects?.findIndex((obj) => {
        return isEqual(obj, filteredObject);
      });

      objects[index] = DestructureHelper.overwriteAttributes(objects[index], attributes);
    });

    return objects;
  }
}
